import { Environment } from './environment.model';

export const environment: Environment = {
  productionMode: true,
  isLocal: false,
  googleTagManagerId: 'GTM-N2RNF95',
  prismicEndpoint: 'https://sunrise-yallo.cdn.prismic.io/api/v2',
  brand: 'yallo',
  oldEshopLink: 'https://eshopengine.yol.ch',
  // Don't need to redirect off the main domain name on production as cloudflare will handle the reverse proxying
  legacyRedirect: '',
  internalUrls: [
    /^(\/(en|de|fr|it))?\/?$/,
    /^(\/(en|de|fr|it))?\/2g(\/.+)?/,
    /^(\/(en|de|fr|it))?\/3g(\/.+)?/,
    /^(\/(en|de|fr|it))?\/roamingtips\/?$/,
    /^(\/(en|de|fr|it))?\/testing(\/.+)?/,
    /^(\/(en|de|fr|it))?\/5g(\/.+)?/,
    /^(\/(en|de|fr|it))?\/awards\/?$/,
    /^(\/(en|de|fr|it))?\/sbb\/?$/,
    /^(\/(en|de|fr|it))?\/cff\/?$/,
    /^(\/(en|de|fr|it))?\/ffs\/?$/,
    /^(\/(en|de|fr|it))?\/best-offer\/?$/,
    /^(\/(en|de|fr|it))?\/best-speed-tv\/?$/,
    /^(\/(en|de|fr|it))?\/best-speed\/?$/,
    /^(\/(en|de|fr|it))?\/blackfriday22\/?$/,
    /^(\/(en|de|fr|it))?\/blackfriday22-base\/?$/,
    /^(\/(en|de|fr|it))?\/cable(\/.+)?/,
    /^(\/(en|de|fr|it))?\/cancel-home-internet(\/.+)?$/,
    /^(\/(en|de|fr|it))?\/cybermonday22(\/.+)?$/,
    /^(\/(en|de|fr|it))?\/cybermonday22-base(\/.+)?$/,
    /^(\/(en|de|fr|it))?\/contest(\/.+)?/,
    /^(\/(en|de|fr|it))?\/fiber(\/.+)?/,
    /^(\/(en|de|fr|it))?\/home(\/.+)?/,
    /^(\/(en|de|fr|it))?\/member(\/.+)?/,
    /^(\/(en|de|fr|it))?\/internet-go(\/.+)?/,
    /^(\/(en|de|fr|it))?\/mobile-products(\/.+)?/,
    /^(\/(en|de|fr|it))?\/mysports\/?$/,
    /^(\/(en|de|fr|it))?\/neptun2022\/?$/,
    /^(\/(en|de|fr|it))?\/not-found(\/.+)?/,
    /^(\/(en|de|fr|it))?\/promo(\/.+)?/,
    /^(\/(en|de|fr|it))?\/shop(\/.+)?/,
    /^(\/(en|de|fr|it))?\/shopping\/?$/,
    /^(\/(en|de|fr|it))?\/terms\/?$/,
    /^(\/(en|de|fr|it))?\/top-deal(\/.+)?/,
    /^(\/(en|de|fr|it))?\/tv-ads\/?$/,
    /^(\/(en|de|fr|it))?\/tv-base\/?$/,
    /^(\/(en|de|fr|it))?\/tv-free-signup\/?$/,
    /^(\/(en|de|fr|it))?\/tv-free\/?$/,
    /^(\/(en|de|fr|it))?\/tv-upgrade\/?$/,
    /^(\/(en|de|fr|it))?\/tv\/?$/,
    /^(\/(en|de|fr|it))?\/welcome(\/.+)?/,
    /^(\/(en|de|fr|it))?\/yallo_prepaid\/?$/,
    /^(\/(en|de|fr|it))?\/yallo-c-est-plus(\/.+)?/,
    /^(\/(en|de|fr|it))?\/yallo-e-di-piu(\/.+)?/,
    /^(\/(en|de|fr|it))?\/yallo-is-more(\/.+)?/,
    /^(\/(en|de|fr|it))?\/yallo-ist-mehr(\/.+)?/,
    /^(\/(en|de|fr|it))?\/yalloween22\/?$/,
    /^(\/(en|de|fr|it))?\/esim(\/.+)?/,
    /^(\/(en|de|fr|it))?\/media(\/.+)?/,
    /^(\/(en|de|fr|it))?\/resubscribe(\/.+)?/,
    /^(\/(en|de|fr|it))?\/cookie-policy(\/.+)?/,
    /^(\/(en|de|fr|it))?\/user-testing(\/.+)?/,
    /^(\/(en|de|fr|it))?\/yallo(\/.+)?/,
    /^(\/(en|de|fr|it))?\/partner(\/.+)?/,
    /^(\/(en|de|fr|it))?\/app\/?$/,
    /^(\/(en|de|fr|it))?\/y-a-yallo\/?$/,
    /^(\/(en|de|fr|it))?\/digital-test\/?$/,
    /^(\/(en|de|fr|it))?\/data-protection\/?$/,
    /^(\/(en|de|fr|it))?\/infla[a-z]{1,2}io[a-z]{1,2}\/?$/,
    /^(\/(en|de|fr|it))?\/summer-blackfriday\/?$/,
    /^(\/(en|de|fr|it))?\/yalloween\/?$/,
    /^(\/(en|de|fr|it))?\/blackfriday(\/.+)?/,
    /^(\/(en|de|fr|it))?\/microspot_swissplus\/?$/,
    /^(\/(en|de|fr|it))?\/microspot_regular\/?$/,
    /^(\/(en|de|fr|it))?\/microspot_superfatxxl\/?$/,
    /^(\/(en|de|fr|it))?\/microspot_swissflat\/?$/,
    /^(\/(en|de|fr|it))?\/tv-appletv\/?$/,
    /^(\/(en|de|fr|it))?\/tv-androidtv\/?$/,
    /^(\/(en|de|fr|it))?\/tv-samsungtv\/?$/,
    /^(\/(en|de|fr|it))?\/auth(\/.+)?/,
    /^(\/(en|de|fr|it))?\/cancellation(\/.+)?/,
    /^(\/(en|de|fr|it))?\/deals\/?$/,
    /^(\/(en|de|fr|it))?\/phishing\/?$/,
    /^(\/(en|de|fr|it))?\/mms\/?$/,
    /^(\/(en|de|fr|it))?\/neptun\/?$/,
    /^(\/(en|de|fr|it))?\/repair\/?$/,
    /^(\/(en|de|fr|it))?\/contest-neptun\/?$/,
    /^(\/(en|de|fr|it))?\/credit-rejection-limit\/?$/,
    /^(\/(en|de|fr|it))?\/credit-rejection-hard-stop\/?$/,
    /^(\/(en|de|fr|it))?\/credit-rejection-too-many-subs\/?$/,
    /^(\/(en|de|fr|it))?\/volte(\/.+)?/,
    /^(\/(en|de|fr|it))?\/costprotection(\/.+)?/,
    /^(\/(en|de|fr|it))?\/ubereats(\/.+)?/,
    /^(\/(en|de|fr|it))?\/webrepublic(\/.+)?/,
    /^(\/(en|de|fr|it))?\/wifi-calling(\/.+)?/,
    /^(\/(en|de|fr|it))?\/oneplus(\/.+)?/,
    /^(\/(en|de|fr|it))?\/roaming-info(\/.+)?/,
    /^(\/(en|de|fr|it))?\/blog(\/.+)?/,
    /^(\/(en|de|fr|it))?\/supermax(\/.+)?/,
    /^(\/(en|de|fr|it))?\/about(\/.+)?/,
    /^(\/(en|de|fr|it))?\/smartwatch(\/.+)?/,
    /^(\/(en|de|fr|it))?\/smartwatch-only(\/.+)?/,
    /^(\/(en|de|fr|it))?\/black-pack(\/.+)?/,
  ],
  fallbackLang: 'de',
  ratePlansPath: '/resources/rateplans.json',
  newMicroServiceEndpoint: 'https://ms.yallo.ch',
  checkoutUrl: 'https://checkout.yallo.ch',
  allowedMsEnvs: [],
  defaultUrlsEnvironment: '',
  yalloFreeTvLoginUrl: 'https://www.yallo.tv/auto-login/?provider=yallo',
  distributionIds: ['E1ONHH5J909LF5', 'E2PDX2ARGKXPQZ', 'E7LL2B0AFQFU0'],
  yalloTvUrl: 'https://yallo.tv/',
  featureFlagClientKey: 'sdk-1AyWzT53SObto3SV',
  selfcareUrl: 'https://my.yallo.ch',
  sprinklerAppId: '6690eb6f187c274712e20ee8_app_220',
};
